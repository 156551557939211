<template>
  <el-popover placement="right" width="400" trigger="click">
    <div>
      <p style="font-size: 18px; font-weight: 600; padding: 0 5px">
        添加{{背景图片}}
      </p>
      <div class="photo-select">
        <el-input v-model="webPhoto" :placeholder="`输入背景图片地址`"></el-input
        ><el-button
          @click="
            if (webPhoto) {
              headPhoto = webPhoto;
            }
          "
          class="sure-photo"
          type="primary"
          size="mini"
          >确定</el-button
        >
      </div>
      <el-upload
        :action="'/api/file/upload/image'"
        class="upload-demo"
        drag
        :on-success="addPhoto"
        :limit="1"
        :on-exceed="exceedFn"
        :file-list="fileList"
        list-type="picture"
        :on-remove="removePhoto"
      >
        <i class="el-icon-upload"></i>
        <p>或</p>
        <div class="el-upload__text">将报告拖到此处,或<em>点击上传</em></div>
      </el-upload>
    </div>
    <slot></slot>
  </el-popover>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.photo-select {
  position: relative;
  margin: 0;
  padding: 0;
  display: flex;
  margin: 5px;
}
</style>